<template>
  <div class="bgcard" id="serverlist">
    <div class="headerMsg">
      <div class="searchlist">
				<div class="query">
					<div class="inputname">
						<input v-model="input" :placeholder="$t('lang.PleaseenterthedevicenameorSn')" />
					</div>
					<div class="searchbtn">
						<button @click="sear">
							<p>{{$t('lang.search')}}</p>
						</button>
					</div>
				</div>
      </div>
    </div>
    <div class="serverfroms">
      <el-table :data="tableData.list" style="width: 100%">
		  <template slot="empty">
          <div class="noCart">{{ $t("lang.tableNull") }}</div>
        </template>
        <el-table-column prop="name" :label="$t('lang.equipmentName')" align="center">
        </el-table-column>
        <el-table-column prop="mid" label="SN" align="center"> </el-table-column>
        <el-table-column prop="userName" :label="$t('lang.User')" align="center">
        </el-table-column>
        <el-table-column prop="online" :label="$t('lang.ResourceStatus')" align="center">
           <template #default="scope">
            <span>{{ scope.row.online ? '在线' : '离线'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="serverName" :label="$t('lang.Portalserver')" align="center">
        </el-table-column>
        <el-table-column prop="loginTime" :label="$t('lang.logintime')" align="center">
          <template #default="scope">
            <span>{{ scope.row.loginTime | formatTimerM}}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('lang.operation')" align="center">
          <template #default="scope">
            <el-button size="small" class="buttons" @click="detailsmsg(scope.row)">{{$t('lang.details')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
			<div style="display: flex; align-items: center" v-if="tableData.list">
				<div class="allNum">{{ $t("lang.total") }}: {{ tableData.total }}</div>
				<el-pagination
					popper-class="paging"
					background
					layout="prev, pager, next"
					:current-page="Number(parsm.page)"
					:total="tableData.total"
					@current-change="handlePageCurrentChange"
					:prev-text="$t('lang.previouspage')"
					:next-text="$t('lang.nextpage')"
				>
				</el-pagination>
			</div>
    </div>
  </div>
</template>

<script>
	import * as server from '@/api/server.js'
export default {
  data() {
    return {
      input: "",
			parsm:{
				page:1,
				size:10,
				nameOrMid: "",
				mid:this.$route.query.mid
			},
      tableData: [],
    };
  },
   filters:{
    formatTimerM: function(value) {
			let date = new Date(value);
				let y = date.getFullYear();
				let MM = date.getMonth() + 1;
				MM = MM < 10 ? "0" + MM : MM;
				let d = date.getDate();
				d = d < 10 ? "0" + d : d;
				let h = date.getHours();
				h = h < 10 ? "0" + h : h;
				let m = date.getMinutes();
				m = m < 10 ? "0" + m : m;
				let s = date.getSeconds();
				s = s < 10 ? "0" + s : s;
				return y + "-" + MM + '-' + d + " " + h + ':' + m + ':' + s;
			},
  },
	mounted() {
		this.getList()
	},
  methods: {
		//获取列表
		async getList(){
			let res=await server.lists(this.parsm)
			console.log(res)
			if(res.data.list){
				this.tableData = res.data;
        //console.log(this.tableData)
			}
		},
		/** 分页页数发生改变 */
		handlePageCurrentChange(page){
			this.parsm.page = page
			this.getList()
		},
		//详情
    detailsmsg(row) {
			console.log(row)
			this.$router.push({
				path: 'equipmentmssg',query:{mid: row.mid, page: this.parsm.page}
			})
		},
		//名称、sn筛选
		sear(){
			this.parsm.page = 1;
			this.parsm.size = 10;
			this.parsm.nameOrMid = this.input;
			this.getList();
		}
  },
};
</script>

<style lang="less" scoped>
.bgcard {
  border: 0px;
  box-sizing: border-box;
  padding: 0.3rem;
  border-radius: 0.02rem;
  background: #222628;
}
.headerMsg {
		display: flex;
		justify-content: flex-end;

		.addbtn {
			button {
				height: 0.5rem;
				border: none;
				outline-style: none;
				padding: 0rem 0.12rem;
				background: #307b37;
				border-radius: 0.1rem;
				font-size: 0.18rem;
				font-family: PingFang SC;
				font-weight: 500;
				color: #ffffff;
			}
		}
}
.buttons {
//   padding: 0px;
//   width: 82px;
//   height: 42px;
  outline-style: none;
  border: none;
  border-radius: 0.04rem;
  font-size: 0.16rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  background-color: #24b4de;
}
</style>
